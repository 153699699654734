import Input from "@referralexchange/rea-ui-react/atoms/Input";
import Content from "@referralexchange/rea-ui-react/atoms/Content";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { capitalize } from "../../../../utils/TextProcessing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { maybe2fa } from "~/utils/Lightning";
import { getLeadUUID, clearLeadUUID } from "~/utils/UUID";
import { countries } from "~/utils/Countries";
import { useRouter } from "next/router";
import { genericFunnels } from "~/config/funnel";
import {
  emailRegexString,
  phoneRegexString,
  nameRegexString,
} from "~/utils/Constants/RegexStrings";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { POSTCONVERT_URL } from "~/utils/FetchingData";
/**
 * ClientContactInformation
 *
 * Client contact information screen. Collects user's contact information.
 *
 * @param {*} props
 * @returns
 */
const ClientContactInformation = (props) => {
  const {
    clientFirstName,
    clientLastName,
    clientEmail,
    clientPhone,
    setClientFirstName,
    setClientLastName,
    setClientEmail,
    setClientPhone,
    nextScreen,
    prevScreen,
    claimed,
    competingProposals,
    agent,
    screensSeen,
    displayError,
    submit,
    setTwoFA,
    clientPhoneCountry,
    setClientPhoneCountry,
    headingProp,
    ctaProp,
    funnel,
    noCompetingProposals,
  } = props;

  const screenKey = "ClientContactInformation";
  const router = useRouter();
  const [nextButtonTittle, setNextButtonTitle] = useState(() => {
    if (!clientFirstName || !clientLastName) {
      return "Name is required";
    } else if (!clientEmail) {
      return "Email is required";
    } else if (!clientPhone) {
      return "Phone is required";
    }
    return null;
  });
  const [loading, setLoading] = useState(false);

  const style = {
    "--fa-primary-color": "rgba(234, 88, 12, 1)",
    "--fa-primary-opacity": 1,
  };

  useEffect(() => {
    if (!clientFirstName) {
      setNextButtonTitle("First and last name is required");
    } else if (clientFirstName && !isValid(clientFirstName, "name")) {
      setNextButtonTitle("Please enter a first and last name");
    } else if (!clientLastName) {
      setNextButtonTitle("First and last name is required");
    } else if (clientLastName && !isValid(clientLastName, "name")) {
      setNextButtonTitle("Please enter a first and last name");
    } else if (!clientEmail) {
      setNextButtonTitle("Email is required");
    } else if (clientEmail && !isValid(clientEmail, "email")) {
      setNextButtonTitle("Please enter a valid email");
    } else if (!clientPhone) {
      setNextButtonTitle("Phone number is required");
    } else if (clientPhone && !isValid(clientPhone, "tel")) {
      setNextButtonTitle("Please enter a valid phone number");
    } else {
      setNextButtonTitle(null);
    }
  });

  /**
   * isValid
   *
   * Validation test for different inputs.
   *
   * @param {string} value
   * @param {string} type
   * @returns {bool}
   */
  const isValid = (value, type) => {
    let valid = false;

    const checkPhoneLength = () => {
      const target =
        countries[clientPhoneCountry]?.phoneFormat.split(".").length - 1;
      return target + (countries[clientPhoneCountry].phone[0].length + 1);
    };

    switch (type) {
      case "name":
        const nameRegex = new RegExp(nameRegexString, "u");
        valid = nameRegex.test(value);
        break;
      case "email":
        const emailRegex = new RegExp(emailRegexString);
        valid = emailRegex.test(value);
        break;
      case "tel":
        const phoneRegex = new RegExp(phoneRegexString);
        valid = phoneRegex.test(value);

        if (valid && clientPhoneCountry && checkPhoneLength() != value.length) {
          valid = false;
        }
        break;
      default:
        valid = false;
        break;
    }
    return valid;
  };

  //set context dependent text strings
  const heading = headingProp
    ? headingProp
    : !claimed
    ? "Last step! Now just add a few contact details"
    : `Last step! Now just add a few contact details for ${capitalize(
        agent.display_name.split(/(\s+)/)[0]
      )} to contact you`;
  const cta = ctaProp
    ? ctaProp
    : claimed && !competingProposals
    ? "Send Message to Agent"
    : "Send Message to Agents";

  const handleClick = () => {
    const check2fa = async () => {
      let [do2FA, response] = await maybe2fa(getLeadUUID());
      setTwoFA(response);

      if (do2FA) {
        do2FA = true;
      }
      setLoading(false);

      if (genericFunnels.includes(funnel) && !do2FA) {
        const leadUUID = getLeadUUID();
        clearLeadUUID();
        const referrer = localStorage.getItem("referrer") || "";

        const postConvertURL = await POSTCONVERT_URL({
          uuid: leadUUID,
        });
        if (postConvertURL) {
          window.location.href = `${postConvertURL}&experience=tv&referrer=${referrer}`;
        }
      } else {
        nextScreen({ key: screenKey, twoFA: do2FA });
      }
    };
    setLoading(true);
    submit()
      .then(() => {
        check2fa();
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        displayError();
      });
  };

  const consentText = (
    <>
      By clicking "{headingProp ? "Get Agents" : "Send Message to Agent"}" I
      acknowledge and agree to ReferralExchange’s{" "}
      <a href="/tos" target="_blank">
        Terms of Use
      </a>{" "}
      and{" "}
      <a href="/privacy" target="_blank">
        Privacy Policy
      </a>
      , which includes binding arbitration and consent to receive electronic
      communications.
    </>
  );

  return (
    <div className="MessageAgentForm__screen" key="contact-info">
      <div className="MessageAgentForm__screen-heading">{heading}</div>
      <div className="MessageAgentForm__contact-subline">
        This is where RealEstateAgents.com and our agents will contact you to
        discuss your needs
      </div>
      {!claimed ? (
        <div className="MessageAgentForm__screen-text">
          You’ll decide which agents to meet with and when.
        </div>
      ) : null}
      <div className="MessageAgentForm__contact-info">
        <Input
          label={"First name"}
          onChange={(e) => setClientFirstName(e.target.value.trim())}
          name={"client-first-name"}
          value={clientFirstName || ""}
          pattern="[a-zA-Z0-9\- ]*"
          autoComplete="given-name"
          required
          maxLength="36"
        />
        <Input
          label={"Last name"}
          onChange={(e) => setClientLastName(e.target.value.trim())}
          name={"client-last-name"}
          value={clientLastName || ""}
          pattern="[a-zA-Z0-9\- ]*"
          autoComplete="family-name"
          required
          maxLength="36"
        />
        <Input
          label={"Email"}
          onChange={(e) => setClientEmail(e.target.value.trim())}
          type={"email"}
          name={"client-email"}
          value={clientEmail || ""}
          autoComplete="email"
          required
          maxLength="255"
        />
      </div>
      <div className="MessageAgentForm__screen-controls">
        <button
          className="MessageAgentForm__get-agents-button"
          type="button"
          onClick={handleClick}
          disabled={
            loading ||
            !clientFirstName ||
            !isValid(clientFirstName, "name") ||
            !clientLastName ||
            !isValid(clientLastName, "name") ||
            !clientEmail ||
            !isValid(clientEmail, "email")
          }
          title={nextButtonTittle}
        >
          {loading && (
            <FontAwesomeIcon icon={faSpinnerThird} spin style={style} />
          )}
          {cta}
        </button>
      </div>
      <div
        id={"contact-consent-text"}
        className="MessageAgentForm__screen-control-text-container --client-info"
      >
        <Content>
          <small>{consentText}</small>
        </Content>
      </div>
      <div className="MessageAgentForm__screen-controls --back">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

ClientContactInformation.propTypes = {
  clientEmail: PropTypes.string,
  clientFirstName: PropTypes.string,
  clientLastName: PropTypes.string,
  clientPhone: PropTypes.string,
  competingProposals: PropTypes.bool.isRequired,
  setClientEmail: PropTypes.func.isRequired,
  setClientFirstName: PropTypes.func.isRequired,
  setClientLastName: PropTypes.func.isRequired,
  setClientPhone: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  claimed: PropTypes.bool.isRequired,
  agent: PropTypes.object.isRequired,
  screensSeen: PropTypes.object.isRequired,
  submit: PropTypes.func,
  displayError: PropTypes.func,
  setTwoFA: PropTypes.func,
};

ClientContactInformation.defaultProps = {
  clientEmail: undefined,
  clientFirstName: undefined,
  clientLastName: undefined,
  clientPhone: undefined,
  competingProposals: true,
  setClientEmail: () => {},
  setClientFirstName: () => {},
  setClientLastName: () => {},
  setClientPhone: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
  claimed: false,
  agent: {},
  screensSeen: new Set(),
  submit: () => {},
  displayError: () => {},
  setTwoFA: () => {},
};

export default ClientContactInformation;
