import { css, StyleSheet } from "aphrodite";
import { useState } from "react";
import { POSTCONVERT_URL } from "~/utils/FetchingData";

export const ViewAgents = ({ leadUUID }) => {
  const [postConvertURL, setPostConvertURL] = useState(null);
  const referrer = localStorage.getItem("referrer") || "";
  if (!leadUUID) {
    return null;
  }
  useEffect(() => {
    const fetchPostConvertURL = async () => {
      const url = await POSTCONVERT_URL({ uuid: leadUUID });
      if (url) {
        setPostConvertURL(url);
      }
    };

    fetchPostConvertURL();
  }, [leadUUID]);
  return (
    <a
      className={css(styles.viewAgents)}
      href={`${postConvertURL}&experience=tv&referrer=${referrer}`}
    >
      <button>View your Agents</button>
    </a>
  );
};

const styles = StyleSheet.create({
  viewAgents: {
    // position: "absolute",
    // bottom: 35,
    // left: "50%",
    // transform: "translateX(-50%)",
    // textDecoration: "underline",
    // marginRight: "auto",
    // marginLeft: "auto",
  },
});
